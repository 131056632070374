import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useEmbedInstructionStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      width: '1px',
      minWidth: '100%',
    },
    closeButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    closeButtonMobile: {
      top: '0px',
      right: '0px',
    },
    dialogRoot: {
      '& .MuiDialog-paper': {
        height: '100%',
      },
    },
    iframeRoot: {
      height: '100%',
      width: '100%',
      borderRadius: '6px',
      border: 'none',
      padding: ({ padding }) => padding || '0',
      background: ({ background }) => background || 'transparent',
    },
  }),
);
