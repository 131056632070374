import React from 'react';
import IframeResizer from 'iframe-resizer-react';

import { Dialog, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { useEmbedInstructionStyles } from './styles';

function paramsToString(params = {}) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
}

export const EmbeddedAppPage = ({
  id,
  token,
  title,
  lng = 'de',
  queryParams = {},
  mode = 'full-screen',
  open,
  onClose,
  basePath = 'umo/view/instruction',
  padding,
  background,
}) => {
  // take queryParams object and create query string from it
  const queryString = paramsToString(queryParams);

  const path = `${basePath}${id ? `/${id}` : ''}`;

  const src = `https://examples.d-simple.com/${path}?token=${token}&mode=embed&lng=${lng}${
    queryString ? `&${queryString}` : ''
  }`;

  // const src = `http://klein.localhost:3200/${path}?token=${token}&mode=embed&lng=${lng}${
  //   queryString ? `&${queryString}` : ''
  // }`;

  // const src = `https://klein.d-simple-sandbox.com/${path}?token=${token}&mode=embed&lng=${lng}${
  //   queryString ? `&${queryString}` : ''
  // }`;

  const theme = useTheme();
  const isLowViewPort = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useEmbedInstructionStyles({ padding, background });

  if (mode === 'full-screen')
    return (
      <Dialog
        onClose={onClose}
        maxWidth="lg"
        className={classes.dialogRoot}
        fullWidth
        fullScreen={isLowViewPort}
        open={open}
        id={`instruction-${id}`}
        keepMounted
      >
        <IconButton className={clsx(classes.closeButton, classes.closeButtonMobile)} onClick={onClose} size="small">
          <CloseRounded />
        </IconButton>
        <iframe title={title} src={src} className={classes.iframeRoot} />
      </Dialog>
    );

  return (
    <IframeResizer
      style={{ borderRadius: '6px' }}
      heightCalculationMethod="taggedElement"
      className={classes.root}
      title={title}
      src={src}
    />
  );
};
